// FONTS

@font-face {
  font-family: "GT Alpina CondLight";
  src: url("../fonts/GTAlpina-CondLight.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GT Alpina";
  src: url("../fonts/GTAlpina-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Maison Medium";
  src: url("../fonts/Maison-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Maison Mono";
  src: url("../fonts/MaisonMono-Demi.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Karla:wght@500&display=swap');
