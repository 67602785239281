// ------------------------------ //
//   Secondary Pages              //
// ------------------------------ //


body.secondary {
  background: linear-gradient(180deg, #010818 0%, #331d97 100%);
  overflow-y: auto;
  min-height: 100vh;

  main.contents {

    padding: 75px 0 10px 0;

    a#logo {
      width: 50%;
      height: 100px;
      display: block;
      margin: 0 auto;
      background: url('../images/logo.png') no-repeat center;
      background-size: contain;
      @include mobile {
        height: 75px;
      }
    }
    @include mobile {
      padding: 100px 0 0 0;

    }

    #investorsList {
      h3 {
        text-align: center;
        line-height: 50px;
      }
    }

    section {
      max-width: 700px;
      width: 100%;
      margin: 40px auto;

      &.intro {
        margin: 30px auto 75px auto;
        p {
          text-align: center;
          color: rgba(white, 0.5);
        }
      }

      ul {
        li {
          list-style-type: circle;
          font-family: 'Karla';
          text-align: left;
          color: rgba(white, 0.7);
          font-size: 19px;
          margin: 10px auto;
        }
      }

      &.two-column {
        display: flex;
        flex-direction: row;
        gap: 50px;

        section {
          margin: 0 auto;
        }

        @include mobile {
          gap: 0;
          flex-direction: column;
          margin: 0 auto;
          display: block;
          section {
            margin: 40px auto;
            display: block;
          }
        }
      }
    }

  }


}
