nav {
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  color: $pink;
  position: relative;
  z-index: 99999;

  @include mobile {
    height: 75px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
  a#logo {
    height: 100%;
    width: 250px;
    background: url("../images/logo_desktop.svg") no-repeat;
    background-size: contain;
    transform: translateY(-7px);
    @include blur($beige);
    @include mobile {
      display: none;
    }
  }
  a#back {
    height: 30px;
    width: 20px;
    margin-top: 10px;
    background: url("../images/icons/back.svg") no-repeat center;
    background-size: contain;
    @include blur($beige);
    @include mobile {
      position: fixed;
      top: 15px;
      left: 15px;
    }
  }

  #burger {
    width: 65px;
    height: 100%;
    cursor: pointer;
    background: url("../images/icons/menu.svg") center no-repeat;
    background-size: contain;
    @include blur($lightPink);

    @include mobile {
      position: fixed;
      top: 15px;
      right: 15px;
      height: 65px;
    }
    &.disabled {
      @include mobile {
        //display: none;
      }
    }
  }

  #signup {
    span {
      width: auto;
      height: 100%;
      display: inline-block;
    }
    form {
      position: relative;
      display: inline-block;
      input {
        border: solid 3px $pink;
        background: none;
        border-radius: 4px;
        font-family: $font-family-base;
        letter-spacing: 1px;
        color: $beige;
        height: calc(100% + 10px);
        padding: 10px 50px 10px 10px;
        position: relative;
        margin-left: 10px;
        position: relative;
      }
      button {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 999;
        background: none;
        border: none;
        img {
          width: auto;
          height: 20px;
        }
      }
    }
  }
}

nav.sticky {
  position: fixed;
  width: calc(100% - ($padding * 2));
  @include mobile {
    width: 100%;
  }
}
