h2,
h3 {
  font-family: $font-family-header, serif;
  margin: 10px auto;
  color: $beige;
  @include blur($beige);
}

h2 {
  font-size: 32px;
  line-height: auto;
  text-align: center;

  width: 100%;
  font-weight: 400;
  line-height: 34px;
}

h3 {
  text-align: left;
  font-size: 25px;
  line-height: 34px;
}

p,
ul {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
  text-align: left;
  color: rgba(white, 0.7);
  margin: 10px auto;
}

// specific to homepage poem
$poemTransition: 2s;

.animated-text {
  position: absolute;
  max-width: 80%;
  width: 900px;
  top: calc(50% - 25px);
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 999;
  height: auto;
  padding: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: all;

  h2 {
    pointer-events: all;
  }
  @include mobile {
    display: none;
  } // hide on mobile
  &.overlay-active {
    opacity: 0;
    h2 {
      animation: none !important;
    }
  } // hide when overlay is open

  h2 {
    font-size: 105px;
    line-height: 95px;
    text-align: center;
    position: absolute;
    pointer-events: none;
    user-select: none;
    width: 100%;
    opacity: 0;
    top: 50%;
    font-family: "GT Alpina CondLight";
    transform: translateY(-50%);
    transition: opacity $poemTransition $easing;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
