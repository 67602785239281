.entrance {
  opacity: 0;
  animation: entrance 0.5s ease-in forwards 0s 1;
  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &.delayed {
    animation-delay: 1s;
    &:nth-child(2) {
      animation-delay: 1.1s;
    }
    &:nth-child(3) {
      animation-delay: 1.2s;
    }
  }
}

@keyframes entrance {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.scale-in {
  opacity: 0;
  animation: scale-in 0.5s ease-in forwards 0s 1;
  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &.delayed {
    animation-delay: 1s;
    &:nth-child(2) {
      animation-delay: 1.1s;
    }
    &:nth-child(3) {
      animation-delay: 1.2s;
    }
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0.85);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#menu {
  &.open main {
    animation: menuOpen 0.5s ease-in-out forwards 0s 1;
  }

  @include mobile {
    &.open main {
      animation: menuOpen 0.5s ease-in-out forwards 0s 1;
    }
  }
}

@keyframes menuOpen {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
}

@keyframes iconOpen {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
