body#homepage {
  footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    z-index: 999;
    @include mobile {
      flex-wrap: wrap;
      bottom: 25px;
      &::before {
        content: "";
        width: 50%;
        height: 150px;
        position: absolute;
        top: -150px;
        margin: 0 auto;
        background: url("../images/logo.png") no-repeat center;
        background-size: contain;
      }
    }
  }
}

footer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @include mobile {
    padding: 0 $padding;
  }

  a {
    width: 300px;
    max-width: 25%;
    height: 50px;
    margin: 15px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    @include mobile {
      margin: 12px 0;
      width: 100%;
      max-width: none;
    }
  }

  button {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 17px;
    padding: 15px auto 20px auto;
    font-size: 18px;
    color: white;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: space-around;
    align-items: center;
    text-decoration: none;
    background: none;
    border: 1.5px solid #fe95eb;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba($beige, 0.5);
    -webkit-transform: translate3d(0, 0, 0);
    color: #fe95eb;
    //filter: blur(0.2px);
    border-radius: 15px;
    @include blur($beige);

    @include mobile() {
      filter: blur(0);
      width: 100%;
      box-shadow: 0px 0px 3px rgba(#fe95eb, 0.25);
    }

    &:hover {
      border: 1.5px solid $beige;
      //color: $beige;
      span {
        //@include blur($beige);
      }
      img {
        //transform: scale(1.1);
        //filter: none;
        //text-shadow: 0px 0px 3px $beige;
      }
    }

    img {
      margin-right: 10px;
      height: 35px;
      width: 35px;
      flex: 0 1 auto;
      -webkit-transform: translate3d(0, 0, 0);
      //@include blur($lightPink);
    }

    span {
      flex: 0 1 auto;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      //@include blur($lightPink);
    }
    @include mobile {
      border: 1px solid #fe95eb;
    }
  }

  ul {
    display: inline-block;
    li {
      display: inline-block;
      padding: 0 20px;
      position: relative;
      &::after {
        position: absolute;
        content: "|";
        right: -5px;
        top: 0;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }
}

$footerHourglassSize: 350px;

body.secondary {
  footer {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin: 25px auto;
    padding-bottom: $footerHourglassSize;

    p {
      text-align: center;
      margin: 15px auto;
    }

    button {
      margin: 25px auto;
      display: inline-block;
      color: $pink;
      font-weight: 500;
      font-size: 22px;
      border: $pink solid 1.5px;
      padding: 20px auto 25px auto;
      @include blur($pink);
    }

    &::after {
      content: "";
      width: $footerHourglassSize;
      height: $footerHourglassSize;
      position: absolute;
      bottom: 0;
      left: calc(50% - ($footerHourglassSize / 2));
      background: url("../images/hourglass.png") no-repeat;
      background-size: contain;
    }
  }
}
