@import "https://fonts.googleapis.com/css2?family=Karla:wght@500&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  border: none;
  margin: 0;
  padding: 0;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: GT Alpina CondLight;
  src: url("GTAlpina-CondLight.e417087b.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: GT Alpina;
  src: url("GTAlpina-Medium.4e1b0365.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Maison Medium;
  src: url("Maison-Medium.fcdf539c.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Maison Mono;
  src: url("MaisonMono-Demi.e52c4604.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html, body {
  width: 100%;
  height: 100%;
  height: fit-content;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: auto;
  font-smooth: always;
  font-family: Maison Mono;
  font-size: 14px;
}

body {
  color: #e5e5e5;
  text-align: center;
  box-sizing: border-box;
  background: #010818;
  margin: 0;
  padding: 25px 25px 45px;
  overflow: hidden;
}

@media (max-width: 599px) {
  body {
    padding: 25px;
  }
}

a {
  color: #e5e5e5;
}

a:hover {
  color: #fe95eb;
  text-decoration: underline;
}

canvas, .container {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 599px) {
  canvas, .container {
    height: 100vh;
    max-height: -webkit-fill-available;
  }
}

canvas {
  cursor: pointer;
}

#stats {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

#stats div {
  top: auto !important;
  bottom: 50px !important;
  left: 15px !important;
}

#soundToggle {
  width: 60px;
  height: 60px;
  z-index: 99999;
  opacity: 1;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  right: 25px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

#soundToggle:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fe95eb;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media (max-width: 599px) {
  #soundToggle {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

@media (max-width: 599px) {
  #soundToggle {
    display: none;
    bottom: calc(100vh - 100px);
    right: 15px;
  }
}

#soundToggle.muted {
  opacity: 1;
}

#soundToggle.muted:after {
  -webkit-mask-image: url("sound_off.8ca53ee5.svg");
  -webkit-mask-image: url("sound_off.8ca53ee5.svg");
  mask-image: url("sound_off.8ca53ee5.svg");
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

#soundToggle.unmuted {
  opacity: 1;
}

#soundToggle.unmuted:after {
  -webkit-mask-image: url("sound_on.5f940569.svg");
  -webkit-mask-image: url("sound_on.5f940569.svg");
  mask-image: url("sound_on.5f940569.svg");
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.hide {
  display: none;
}

nav {
  width: 100%;
  height: 75px;
  color: #fa57c8;
  z-index: 99999;
  place-content: flex-start space-between;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (max-width: 599px) {
  nav {
    height: 75px;
    width: 100%;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  }
}

nav a#logo {
  height: 100%;
  width: 250px;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translateY(-7px);
  background: url("logo_desktop.5ba26d70.svg") 0 0 / contain no-repeat;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  nav a#logo {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

@media (max-width: 599px) {
  nav a#logo {
    display: none;
  }
}

nav a#back {
  height: 30px;
  width: 20px;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: url("back.76e890c2.svg") center / contain no-repeat;
  margin-top: 10px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  nav a#back {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

@media (max-width: 599px) {
  nav a#back {
    position: fixed;
    top: 15px;
    left: 15px;
  }
}

nav #burger {
  width: 65px;
  height: 100%;
  cursor: pointer;
  filter: drop-shadow(0 0 6px #fe95eb66);
  text-shadow: 0 0 2px #fe95eb4d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: url("menu.60f17584.svg") center / contain no-repeat;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  nav #burger {
    filter: blur() drop-shadow(0 0 4px #fe95eb59);
    text-shadow: 0 0 3px #fe95eb54;
  }
}

@media (max-width: 599px) {
  nav #burger {
    height: 65px;
    position: fixed;
    top: 15px;
    right: 15px;
  }
}

nav #signup span {
  width: auto;
  height: 100%;
  display: inline-block;
}

nav #signup form {
  display: inline-block;
  position: relative;
}

nav #signup form input {
  letter-spacing: 1px;
  color: #fdf3c9;
  height: calc(100% + 10px);
  background: none;
  border: 3px solid #fa57c8;
  border-radius: 4px;
  margin-left: 10px;
  padding: 10px 50px 10px 10px;
  font-family: Maison Mono;
  position: relative;
}

nav #signup form button {
  z-index: 999;
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

nav #signup form button img {
  width: auto;
  height: 20px;
}

nav.sticky {
  width: calc(100% - 50px);
  position: fixed;
}

@media (max-width: 599px) {
  nav.sticky {
    width: 100%;
  }
}

.backdrop-blur {
  background-color: #010818f2;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .backdrop-blur {
    -webkit-backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: #010818bf;
  }
}

#form-overlay {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 99999;
  padding: 25px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#form-overlay #formClose {
  width: 65px;
  height: 65px;
  z-index: 10000000;
  cursor: pointer;
  filter: drop-shadow(0 0 6px #fe95eb66);
  text-shadow: 0 0 2px #fe95eb4d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: scale(.9);
  background: url("close.113fa5d0.svg") center no-repeat;
  position: absolute;
  top: 30px;
  right: 25px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  #form-overlay #formClose {
    filter: blur() drop-shadow(0 0 4px #fe95eb59);
    text-shadow: 0 0 3px #fe95eb54;
  }
}

@media (max-width: 599px) {
  #form-overlay #formClose {
    display: block;
    top: 20px;
    right: 15px;
  }
}

#form-overlay form {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#form-overlay form .fieldset {
  min-height: 0;
  width: 100%;
  opacity: 0;
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#form-overlay form .fieldset.active {
  opacity: 1;
  display: inline;
}

@media (max-width: 599px) {
  #form-overlay form .fieldset {
    padding: 50px 25px;
  }
}

#form-overlay.overlay-active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  display: block;
}

#form-overlay.overlay-active.android-active form#form-android, #form-overlay.overlay-active.ambassador-active form#form-ambassador {
  display: inline;
}

.message {
  visibility: hidden;
  z-index: 9999;
  width: 100%;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.message.active {
  visibility: visible;
  display: block;
}

@media (max-width: 599px) {
  .message {
    width: 100%;
  }
}

.fieldset + .fieldset, form + form, .message {
  margin: 20px;
}

@media (max-width: 599px) {
  .fieldset + .fieldset, form + form, .message {
    margin: 0;
    padding: 20px;
  }
}

.fieldset {
  min-height: 500px;
}

label, .message p {
  color: #fdf3c9;
  text-align: center;
  text-shadow: 0 0 20px #fdf3c980;
  padding-bottom: 60px;
  font-family: GT Alpina;
  font-size: 28px;
  font-weight: 500;
  display: block;
}

form input, form textarea, form select, form .checkbox_block {
  color: #71728c;
  color: #fff;
  opacity: .8;
  text-shadow: 0 0 4px #ffffff80;
  box-sizing: border-box;
  text-align: center;
  width: 400px;
  height: 60px;
  cursor: pointer;
  background: #ffffff1c;
  border: 2px solid #fa57c880;
  border-radius: 13px;
  margin: 15px auto;
  font-family: Maison Medium;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  display: block;
  box-shadow: 0 0 4px #ffffff40;
}

form input::placeholder, form textarea::placeholder, form select::placeholder, form .checkbox_block::placeholder {
  color: #ffffffbf;
}

@media (max-width: 599px) {
  form input, form textarea, form select, form .checkbox_block {
    width: 100%;
  }
}

button[type="submit"], button[type="button"] {
  color: #fff;
  width: 90px;
  height: 40px;
  cursor: pointer;
  background: linear-gradient(#fa57c8 0%, #5c2fb9 100%);
  border: none;
  border-radius: 100px;
  margin-top: 60px;
  box-shadow: 0 4px 4px #00000038;
}

button[type="submit"].disabled, button[type="button"].disabled {
  pointer-events: none;
  background: #ffffff1a;
  border-radius: 200px;
}

button[type="submit"].disabled img, button[type="button"].disabled img {
  opacity: .5;
}

.checkbox_block {
  padding-top: 15px;
  padding-left: 15px;
  display: flex !important;
}

.checkbox_block label {
  color: #fff;
  text-align: left;
  width: auto;
  cursor: pointer;
  flex-grow: 2;
  margin-left: 15px;
  padding: 0;
  font-family: Maison Medium;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
}

input[type="checkbox"] {
  appearance: none;
  background-color: var(--form-background);
  font: inherit;
  color: currentColor;
  width: 26px;
  height: 26px;
  border: .15em solid ;
  border-radius: .15em;
  place-content: center;
  margin: 0;
  display: grid;
}

input[type="checkbox"]:before {
  content: "";
  width: 26px;
  height: 26px;
  transform-origin: center;
  box-shadow: inset 1em 1em var(--form-control-color);
  background: #c154b8;
  transition: transform .66s ease-in-out;
  transform: scale(0);
}

input[type="checkbox"]:checked:before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline-offset: max(2px, .15em);
  outline: max(2px, .15em) solid ;
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.dg input[type="checkbox"], .dg input[type="checkbox"]:before {
  width: 16px;
  height: 16px;
}

#menu {
  width: 100%;
  height: 100%;
  z-index: 99999;
  padding: 25px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#menu.open {
  display: block;
}

#menu.overlay-active {
  display: none;
}

#menu .inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#menu #navClose, #menu #navBack {
  width: 65px;
  height: 65px;
  z-index: 99990;
  cursor: pointer;
  opacity: 1;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: scale(1);
  position: absolute;
  top: 30px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  #menu #navClose, #menu #navBack {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

@media (max-width: 599px) {
  #menu #navClose, #menu #navBack {
    top: 15px;
  }
}

#menu #navBack {
  background: url("back.76e890c2.svg") center / 35% no-repeat;
  left: 0;
}

#menu #navClose {
  background: url("close.113fa5d0.svg") center no-repeat;
  right: 25px;
}

@media (max-width: 599px) {
  #menu #navClose {
    right: 15px;
  }
}

#menu ul#links, #menu ul#careers {
  text-align: center;
  color: #fdf3c9;
  align-items: center;
  font-family: Maison Medium;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
}

@media (max-width: 599px) {
  #menu ul#links, #menu ul#careers {
    font-size: 42px;
    line-height: 50px;
  }
}

#menu ul#links li, #menu ul#careers li {
  margin: 25px 0;
  padding: 0 10px;
  display: block;
}

#menu ul#links li a, #menu ul#careers li a {
  color: #fdf3c9;
  cursor: pointer;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  text-decoration: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  #menu ul#links li a, #menu ul#careers li a {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

#menu ul#links li a:hover, #menu ul#careers li a:hover {
  filter: drop-shadow(0 0 6px #fe95eb66);
  text-shadow: 0 0 2px #fe95eb4d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  color: #fe95eb;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  #menu ul#links li a:hover, #menu ul#careers li a:hover {
    filter: blur() drop-shadow(0 0 4px #fe95eb59);
    text-shadow: 0 0 3px #fe95eb54;
  }
}

@media (max-width: 599px) {
  #menu ul#careers {
    font-size: 32px;
  }
}

#menu ul#socials {
  text-align: center;
  color: #fdf3c9;
  align-items: center;
  margin-top: 50px;
  line-height: 80px;
}

#menu ul#socials li {
  padding: 0 15px;
  display: inline-block;
}

#menu ul#socials li a {
  color: #fdf3c9;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  width: 50px;
  height: 50px;
  background-size: contain;
  text-decoration: none;
  display: inline-block;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  #menu ul#socials li a {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

#menu ul#socials li a#discord {
  background: url("discord.81a8c40c.svg") center no-repeat;
}

#menu ul#socials li a#instagram {
  background: url("instagram.ac1dd202.svg") center no-repeat;
}

#menu ul#socials li a#tiktok {
  background: url("tiktok.d577bec2.svg") center no-repeat;
}

#menu ul#socials li a:hover {
  text-decoration: underline;
}

#menu .hidden {
  visibility: hidden;
  opacity: 0;
  display: none;
}

h2, h3 {
  color: #fdf3c9;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  margin: 10px auto;
  font-family: GT Alpina, serif;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  h2, h3 {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

h2 {
  font-size: 32px;
  line-height: auto;
  text-align: center;
  width: 100%;
  font-weight: 400;
  line-height: 34px;
}

h3 {
  text-align: left;
  font-size: 25px;
  line-height: 34px;
}

p, ul {
  text-align: left;
  color: #ffffffb3;
  margin: 10px auto;
  font-family: Karla;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.animated-text {
  max-width: 80%;
  width: 900px;
  cursor: pointer;
  z-index: 999;
  height: auto;
  pointer-events: all;
  padding: 0;
  transition: opacity .5s ease-in-out;
  position: absolute;
  top: calc(50% - 25px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.animated-text h2 {
  pointer-events: all;
}

@media (max-width: 599px) {
  .animated-text {
    display: none;
  }
}

.animated-text.overlay-active {
  opacity: 0;
}

.animated-text.overlay-active h2 {
  animation: none !important;
}

.animated-text h2 {
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  opacity: 0;
  font-family: GT Alpina CondLight;
  font-size: 105px;
  line-height: 95px;
  transition: opacity 2s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.animated-text h2.active {
  opacity: 1;
  pointer-events: all;
}

body#homepage footer {
  z-index: 999;
  flex-flow: row;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 0;
}

@media (max-width: 599px) {
  body#homepage footer {
    flex-wrap: wrap;
    bottom: 25px;
  }

  body#homepage footer:before {
    content: "";
    width: 50%;
    height: 150px;
    background: url("logo.86df6e4b.png") center / contain no-repeat;
    margin: 0 auto;
    position: absolute;
    top: -150px;
  }
}

footer {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 599px) {
  footer {
    padding: 0 25px;
  }
}

footer a {
  width: 300px;
  max-width: 25%;
  height: 50px;
  margin: 15px;
  text-decoration: none;
}

footer a:hover {
  text-decoration: none;
}

@media (max-width: 599px) {
  footer a {
    width: 100%;
    max-width: none;
    margin: 12px 0;
  }
}

footer button {
  width: 100%;
  height: 50px;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
  color: #fe95eb;
  filter: drop-shadow(0 0 6px #fdf3c966);
  text-shadow: 0 0 2px #fdf3c94d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: none;
  border: 1.5px solid #fe95eb;
  border-radius: 15px;
  flex-flow: row;
  place-content: space-around center;
  align-items: center;
  padding: 15px auto 20px;
  font-size: 18px;
  line-height: 25px;
  text-decoration: none;
  display: flex;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  box-shadow: 0 0 6px #fdf3c980;
}

@media (max-width: 599px) {
  footer button {
    filter: blur() drop-shadow(0 0 4px #fdf3c959);
    text-shadow: 0 0 3px #fdf3c954;
  }
}

@media (max-width: 599px) {
  footer button {
    filter: blur();
    width: 100%;
    box-shadow: 0 0 3px #fe95eb40;
  }
}

footer button:hover {
  border: 1.5px solid #fdf3c9;
}

footer button img {
  height: 35px;
  width: 35px;
  flex: 0 auto;
  margin-right: 10px;
  -webkit-transform: translate3d(0, 0, 0);
}

footer button span {
  flex: 0 auto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

@media (max-width: 599px) {
  footer button {
    border: 1px solid #fe95eb;
  }
}

footer ul {
  display: inline-block;
}

footer ul li {
  padding: 0 20px;
  display: inline-block;
  position: relative;
}

footer ul li:after {
  content: "|";
  position: absolute;
  top: 0;
  right: -5px;
}

footer ul li:last-child:after {
  display: none;
}

footer ul li a {
  text-decoration: none;
}

body.secondary footer {
  width: 100%;
  text-align: center;
  margin: 25px auto;
  padding-bottom: 350px;
  display: block;
  position: relative;
}

body.secondary footer p {
  text-align: center;
  margin: 15px auto;
}

body.secondary footer button {
  color: #fa57c8;
  filter: drop-shadow(0 0 6px #fa57c866);
  text-shadow: 0 0 2px #fa57c84d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  border: 1.5px solid #fa57c8;
  margin: 25px auto;
  padding: 20px auto 25px;
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (max-width: 599px) {
  body.secondary footer button {
    filter: blur() drop-shadow(0 0 4px #fa57c859);
    text-shadow: 0 0 3px #fa57c854;
  }
}

body.secondary footer:after {
  content: "";
  width: 350px;
  height: 350px;
  background: url("hourglass.2e6ee3f9.png") 0 0 / contain no-repeat;
  position: absolute;
  bottom: 0;
  left: calc(50% - 175px);
}

body.secondary {
  min-height: 100vh;
  background: linear-gradient(#010818 0%, #331d97 100%);
  overflow-y: auto;
}

body.secondary main.contents {
  padding: 75px 0 10px;
}

body.secondary main.contents a#logo {
  width: 50%;
  height: 100px;
  background: url("logo.86df6e4b.png") center / contain no-repeat;
  margin: 0 auto;
  display: block;
}

@media (max-width: 599px) {
  body.secondary main.contents a#logo {
    height: 75px;
  }
}

@media (max-width: 599px) {
  body.secondary main.contents {
    padding: 100px 0 0;
  }
}

body.secondary main.contents #investorsList h3 {
  text-align: center;
  line-height: 50px;
}

body.secondary main.contents section {
  max-width: 700px;
  width: 100%;
  margin: 40px auto;
}

body.secondary main.contents section.intro {
  margin: 30px auto 75px;
}

body.secondary main.contents section.intro p {
  text-align: center;
  color: #ffffff80;
}

body.secondary main.contents section ul li {
  text-align: left;
  color: #ffffffb3;
  margin: 10px auto;
  font-family: Karla;
  font-size: 19px;
  list-style-type: circle;
}

body.secondary main.contents section.two-column {
  flex-direction: row;
  gap: 50px;
  display: flex;
}

body.secondary main.contents section.two-column section {
  margin: 0 auto;
}

@media (max-width: 599px) {
  body.secondary main.contents section.two-column {
    flex-direction: column;
    gap: 0;
    margin: 0 auto;
    display: block;
  }

  body.secondary main.contents section.two-column section {
    margin: 40px auto;
    display: block;
  }
}

section#loading-screen {
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  z-index: 99999;
  opacity: 1;
  pointer-events: none;
  background: #010818e6;
  justify-content: center;
  align-items: center;
  margin: 0;
  transition: opacity 1s;
  position: fixed;
  top: 0;
  left: 0;
}

#loading-screen.fade-out {
  opacity: 0;
}

#loader {
  --clock-color: $ beige;
  --clock-width: 4rem;
  --clock-radius: calc(var(--clock-width)  / 2);
  --clock-minute-length: calc(var(--clock-width)  * .4);
  --clock-hour-length: calc(var(--clock-width)  * .2);
  --clock-thickness: .3rem;
  left: calc(50% - var(--clock-radius) );
  top: calc(50% - var(--clock-width) );
  z-index: 99999;
  width: var(--clock-width);
  height: var(--clock-width);
  border: 0 solid #331d97;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
}

#loader:before, #loader:after {
  content: "";
  left: calc(var(--clock-radius)  * 1);
  top: calc(var(--clock-radius)  * .25);
  width: var(--clock-thickness);
  transform-origin: center calc(100% - var(--clock-thickness)  / 2);
  background: #ffa464;
  border-radius: 10px;
  animation: spin linear infinite;
  position: absolute;
}

#loader:before {
  height: var(--clock-minute-length);
  animation-duration: 2s;
}

#loader:after {
  top: calc(var(--clock-radius)  * .25 + var(--clock-hour-length) );
  height: var(--clock-hour-length);
  animation-duration: 15s;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.entrance {
  opacity: 0;
  animation: entrance .5s ease-in forwards;
}

.entrance:nth-child(2) {
  animation-delay: .1s;
}

.entrance:nth-child(3) {
  animation-delay: .2s;
}

.entrance.delayed {
  animation-delay: 1s;
}

.entrance.delayed:nth-child(2) {
  animation-delay: 1.1s;
}

.entrance.delayed:nth-child(3) {
  animation-delay: 1.2s;
}

@keyframes entrance {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.scale-in {
  opacity: 0;
  animation: scale-in .5s ease-in forwards;
}

.scale-in:nth-child(2) {
  animation-delay: .1s;
}

.scale-in:nth-child(3) {
  animation-delay: .2s;
}

.scale-in.delayed {
  animation-delay: 1s;
}

.scale-in.delayed:nth-child(2) {
  animation-delay: 1.1s;
}

.scale-in.delayed:nth-child(3) {
  animation-delay: 1.2s;
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(.85);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#menu.open main {
  animation: menuOpen .5s ease-in-out forwards;
}

@media (max-width: 599px) {
  #menu.open main {
    animation: menuOpen .5s ease-in-out forwards;
  }
}

@keyframes menuOpen {
  0% {
    opacity: 0;
    transform: scale(.9)translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: scale(1)translateY(-50%);
  }
}

@keyframes iconOpen {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*# sourceMappingURL=index.5d677450.css.map */
