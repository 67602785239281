// ------------------------------ //
//   Form                         //
// ------------------------------ //

/* slightly transparent fallback */
.backdrop-blur {
  background-color: rgba($darkNavy3, 0.95);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    background-color: rgba($darkNavy3, 0.75);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }
}

#form-overlay {
  backdrop-filter: blur(15px);
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: none;
  padding: $padding;

  #formClose {
    position: absolute;
    top: 30px;
    right: 25px;
    width: 65px;
    height: 65px;
    background: url("../images/icons/close.svg") no-repeat center;
    z-index: 9999999;
    cursor: pointer;
    transform: scale(0.9);
    @include blur($lightPink);
    @include mobile {
      top: 20px;
      right: 15px;
      display: block;
    }
  }

  form {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .fieldset {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      min-height: 0;
      width: 100%;
      left: 0;
      opacity: 0;
      display: none;
      &.active {
        opacity: 1;
        display: inline;
      }
      @include mobile() {
        padding: 50px 25px;
      }
    }
  }

  &.overlay-active {
    opacity: 1;
    visibility: visible;
    display: block;
    pointer-events: all;
    &.android-active {
      form#form-android {
        display: inline;
      }
    }
    &.ambassador-active {
      form#form-ambassador {
        display: inline;
      }
    }
  }
}

.message {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
  display: none;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  &.active {
    display: block;
    visibility: visible;
  }
  @include mobile() {
    width: 100%;
  }
}

.fieldset + .fieldset,
form + form,
.message {
  margin: $space;
  @include mobile() {
    margin: 0;
    padding: 20px;
  }
}

.fieldset {
  min-height: 500px;
}

label,
.message p {
  color: $beige;
  display: block;
  font-size: 28px;
  font-weight: bold;
  font-family: "GT Alpina";
  font-weight: 500;
  padding-bottom: 60px;
  text-align: center;
  text-shadow: 0 0 20px rgba($beige, 0.5);
}

form {
  input,
  textarea,
  select,
  .checkbox_block {
    border: 1px solid $detail;
    color: $text-dark;
    font-size: 100%;
    font-family: "Maison Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    color: #ffffff;
    opacity: 0.8;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.11);
    border: 2px solid rgba($pink, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    border-radius: 13px;
    text-align: center;
    width: 400px;
    height: 60px;
    cursor: pointer;
    line-height: 50px;
    display: block;
    margin: 15px auto;
    &::placeholder {
      color: rgba(#fff, 0.75);
    }
    @include mobile() {
      width: 100%;
    }
  }
}

button[type="submit"],
button[type="button"] {
  background: linear-gradient(180deg, $pink 0%, $purple 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
  border-radius: 100px;
  color: white;
  width: 90px;
  height: 40px;
  border: none;
  margin-top: 60px;
  cursor: pointer;

  &.disabled {
    background: rgba(#ffffff, 0.1);
    border-radius: 200px;
    pointer-events: none;
    img {
      opacity: 0.5;
    }
  }
}

.checkbox_block {
  padding-top: 15px;
  padding-left: 15px;
  display: flex !important;
  label {
    font-family: "Maison Medium";
    font-size: 18px;
    color: white;
    text-align: left;
    display: inline-block;
    width: auto;
    margin-left: 15px;
    padding: 0;
    line-height: 30px;
    flex-grow: 2;
    cursor: pointer;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 26px;
  height: 26px;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  //transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 26px;
  height: 26px;
  transform: scale(0);
  transform-origin: center;
  transition: 0.66s transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background: #c154b8;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.dg input[type="checkbox"] {
  width: 16px;
  height: 16px;
  &::before {
    width: 16px;
    height: 16px;
  }
}
