#menu {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: none;
  padding: $padding;
  &.open {
    display: block;
  }

  &.overlay-active {
    display: none;
  }

  .inner {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
  }

  #navClose,
  #navBack {
    position: absolute;
    top: 30px;
    width: 65px;
    height: 65px;
    z-index: 99990;
    cursor: pointer;
    opacity: 1;
    transform: scale(1);

    @include blur($beige);
    @include mobile {
      top: 15px;
    }
  }
  #navBack {
    background: url("../images/icons/back.svg") center no-repeat;
    background-size: 35%;
    left: 0px;
  }
  #navClose {
    background: url("../images/icons/close.svg") center no-repeat;
    right: 25px;
    @include mobile {
      right: 15px;
    }
  }

  ul#links,
  ul#careers {
    font-family: "Maison Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 80px;
    align-items: center;
    text-align: center;
    color: $beige;
    @include mobile {
      font-size: 42px;
      line-height: 50px;
    }
    li {
      display: block;
      padding: 0 10px;
      margin: 25px 0;
      a {
        text-decoration: none;
        color: $beige;
        cursor: pointer;
        @include blur($beige);
      }
      a:hover {
        @include blur($lightPink);
        color: $lightPink;
      }
    }
  }

  ul#careers {
    @include mobile {
      font-size: 32px;
    }
  }

  ul#socials {
    line-height: 80px;
    align-items: center;
    text-align: center;
    color: $beige;
    margin-top: 50px;
    li {
      display: inline-block;
      padding: 0 15px;

      a {
        text-decoration: none;
        color: $beige;
        @include blur($beige);
        width: 50px;
        height: 50px;
        display: inline-block;
        background-size: contain;
        &#discord {
          background: url("../images/icons/discord.svg") no-repeat center;
        }
        &#instagram {
          background: url("../images/icons/instagram.svg") no-repeat center;
        }
        &#tiktok {
          background: url("../images/icons/tiktok.svg") no-repeat center;
        }
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .hidden {
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}
