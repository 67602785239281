html,
body {
  width: 100%;
  height: 100%;
  height: fit-content;
  font-size: $font-size-base-px;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: auto;
  font-smooth: always;
}

body {
  color: $color-text;
  background: $darkNavy3;
  text-align: center;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 25px 25px 45px 25px;
  box-sizing: border-box;
  @include mobile {
    padding: $padding;
  }
}

a {
  color: $grey;
  &:hover {
    color: $lightPink;
    text-decoration: underline;
  }
}

canvas,
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @include mobile {
    height: 100vh;
    max-height: -webkit-fill-available;
    //transform: scale(1.25) translateY(-9%);
  }
}

canvas {
  cursor: pointer;
}

#stats {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  div {
    bottom: 50px !important;
    top: auto !important;
    left: 15px !important;
  }
}

#soundToggle {
  position: absolute;
  bottom: 50px;
  right: 25px;
  width: 60px;
  height: 60px;
  z-index: 99999;
  opacity: 1;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fe95eb;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include blur($beige);

  @include mobile {
    bottom: calc(100vh - 100px);
    right: 15px;
    display: none;
  }
  cursor: pointer;
  &.muted {
    opacity: 1;
    &::after {
      -webkit-mask-image: url("../images/icons/sound_off.svg");
      mask-image: url("../images/icons/sound_off.svg");
      mask-size: 100%;
    }
  }
  &.unmuted {
    opacity: 1;
    &::after {
      -webkit-mask-image: url("../images/icons/sound_on.svg");
      mask-image: url("../images/icons/sound_on.svg");
      mask-size: 100%;
    }
  }
}

.hide {
  display: none;
}
