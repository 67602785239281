// -------------------------------------

/**
 * Colors
 * =================================
*/

//light

$pink: #fa57c8;
$lightPink: #fe95eb;
$purple: #5c2fb9;
$blue: #3272d9;
$orange: #ffa464;
$beige: #fdf3c9;

//dark
$deepPurple: #331d97;
$navy: #2e31cf;
$darkNavy: #08174b;
$darkNavy2: #011136;
$darkNavy3: #010818;
$black: $darkNavy3;

//text
$grey: #e5e5e5;

$glow-a: rgba(253, 243, 201, 0.15);
$glow-b: rgba(253, 148, 235, 0.15);

/**
 * Gradients
 * =================================
*/

$list1: $blue, $orange;
$list2: $pink, $orange;
$list3: $beige, $navy;
$list4: $beige, $orange;
$list5: $orange, $purple;
$list6: $darkNavy, $blue;
$list7: $darkNavy, $pink;
$list8: $darkNavy, $purple;
$list9: $blue, $deepPurple;
$list10: $darkNavy3, $deepPurple;

@mixin gradient($list) {
  background: linear-gradient($list);
}

@mixin blur($color) {
  filter: drop-shadow(0px 0px 6px rgba($color, 0.4));
  text-shadow: 0px 0px 2px rgba($color, 0.3);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  @include mobile() {
    filter: blur(0) drop-shadow(0px 0px 4px rgba($color, 0.35));
    text-shadow: 0px 0px 3px rgba($color, 0.33);
  }
}

@mixin blur-2($color) {
  filter: drop-shadow(0px 0px 8px rgba($color, 1));
  text-shadow: 0px 0px 2px rgba($color, 1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  @include mobile() {
    filter: blur(0) drop-shadow(0px 0px 4px rgba($color, 0.35));
    text-shadow: 0px 0px 3px rgba($color, 0.33);
  }
}

/**
 * Typography
 * =================================
*/

$font-size-base-px: 14px;
$font-size: $font-size-base-px;
$font-family-base: "Maison Mono";
$font-family-header: "GT Alpina";
$font-family-paragraph: "Karla";
$color-text: $grey;

$padding: 25px;

/**
 * Form Styling
 * =================================
*/
$space-xxs: 5px;
$space-xs: 10px;
$space-s: 15px;
$space: 20px;
$space-l: 25px;
$space-xl: 30px;
$space-xxl: 40px;
$text-dark: #71728c;
$detail: $pink;
$action: $pink;
$action-dark: $pink;

/**
 * Animation
 * =================================
*/

$totalLoopLength: 50s; // length from sentence one to sentence one
$sentences: 5; // only change if you've added an <h2></h2>
$scaleIn: 0.95;
$scaleOut: 1.1;
$rotateIn: 0deg;
$rotateOut: 0deg;
$easing: ease-in-out;

/**

Media Queries

**/

@mixin mobile {
  @media (max-width: 599px) {
    @content;
  }
}
